import { HttpParams } from '@angular/common/http';

/**
 * Compose http params with param values.
 * @param paramValues Param values.
 */
// Any because interface object couldn't extends `Record<string, unknown>`.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function composeHttpParams<T extends Record<string, any>>(paramValues: T): HttpParams {
	let params = new HttpParams();
	Object
		.entries(paramValues)
		.forEach(([key, value]) => {
			params = applyParam(params, key, value);
		});
	return params;
}

/**
 * Apply param to http params if value isn't undefined or null.
 * @param params Http params.
 * @param key Key.
 * @param value Value.
 */
function applyParam(params: HttpParams, key: string, value: unknown): HttpParams {
	if (isFitParams(value)) {
		return params.set(key, value);
	}
	return params;
}

/**
 * Check value param fit to params.
 * @param value Param value.
 */
function isFitParams(value: unknown): value is (number | string | boolean) {
	return (
		typeof value === 'string' ||
		typeof value === 'number' ||
		typeof value === 'boolean'
	);
}
