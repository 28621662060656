import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { createPaginationDtoSchemaParser } from '../dtos/pagination.dto';
import { userAnalyticDto } from '../dtos/user-analytic.dto';
import { UserFilterParamsDto } from '../dtos/user-filter-params.dto';
import { userDtoSchema, UserExtendedDto, userExtendedDtoSchema } from '../dtos/user.dto';
import { AppErrorMapper } from '../mappers/app-error.mapper';
import { PaginationMapper } from '../mappers/pagination.mapper';
import { UserAnalysisFilterParamsMapper } from '../mappers/user-analytic-filter-params.mapper';
import { UserAnalyticMapper } from '../mappers/user-analytic.mapper';
import { UserExtendedMapper } from '../mappers/user-extended.mapper';
import { UserFilterParamsMapper } from '../mappers/user-filter-params.mapper';
import { UserMapper } from '../mappers/user.mapper';
import { Pagination } from '../models/pagination';
import { User, UserCreation, UserEdit, UserExtended } from '../models/user';
import { UserAnalysisFilterParams } from '../models/user-analysis-filter-params';
import { UserAnalytic } from '../models/user-analytic';
import { UsersFilterParams } from '../models/users-filter-params';
import { composeHttpParams } from '../utils/compose-http-params';

import { UserDetailFilterParamsMapper } from '../mappers/user-detail-filter-params.mapper';

import { UserDetailFilterParams } from '../models/user-detail-filter-params';

import { AppUrlsConfig } from './app-urls.config';

/** Performs CRUD operations for users. */
@Injectable({
	providedIn: 'root',
})
export class UserApiService {

	private readonly apiUrls = inject(AppUrlsConfig);

	private readonly httpClient = inject(HttpClient);

	private readonly userMapper = inject(UserMapper);

	private readonly userExtendedMapper = inject(UserExtendedMapper);

	private readonly userAnalyticMapper = inject(UserAnalyticMapper);

	private readonly paginationMapper = inject(PaginationMapper);

	private readonly userAnalysisFilterParamsMapper = inject(UserAnalysisFilterParamsMapper);

	private readonly userFilterParamsMapper = inject(UserFilterParamsMapper);

	private readonly appErrorMapper = inject(AppErrorMapper);

	private readonly userDetailFilterParamsMapper = inject(UserDetailFilterParamsMapper);

	/** Returns current user info.*/
	public getCurrentUser(): Observable<User> {
		return this.httpClient.get<unknown>(
			this.apiUrls.user.currentProfile,
		)
			.pipe(
				map(response => userDtoSchema.parse(response)),
				map(userDto => this.userMapper.fromDto(userDto)),
			);
	}

	/**
	 * Get users.
	 * @param filters Filters.
	 */
	public getUsers(filters?: UsersFilterParams): Observable<Pagination<UserExtended>> {
		const optionsDto: UserFilterParamsDto | {} = filters ? this.userFilterParamsMapper.toDto(filters) : {};
		const params = composeHttpParams(optionsDto);

		return this.httpClient.get<unknown>(
			this.apiUrls.users.users,
			{ params },
		)
			.pipe(
				map(response => createPaginationDtoSchemaParser(userExtendedDtoSchema).parse(response)),
				map(pagination =>
					this.paginationMapper.fromDto(pagination, (userDto: UserExtendedDto) => this.userExtendedMapper.fromDto(userDto))),
			);
	}

	/**
	 * Get user by ID.
	 * @param id User ID.
	 * @param filterParams Filter params.
	 */
	public getUserById(id: User['id'], filterParams: UserDetailFilterParams): Observable<UserExtended> {
		const paramsDto = this.userDetailFilterParamsMapper.toDto(filterParams);
		const composedParams = composeHttpParams(paramsDto);
		return this.httpClient.get<unknown>(
			this.apiUrls.users.details(id),
			{ params: composedParams },
		)
			.pipe(
				map(response => userExtendedDtoSchema.parse(response)),
				map(userDto => this.userExtendedMapper.fromDto(userDto)),
			);
	}

	/**
	 * Create user.
	 * @param data User creation data.
	 */
	public createUser(data: UserCreation): Observable<void> {
		return this.httpClient.post<unknown>(this.apiUrls.users.createUser, this.userMapper.toCreationUserDto(data)).pipe(
			this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(
				this.userMapper,
			),
			map(() => undefined),
		);
	}

	/**
	 * Edit user.
	 * @param user User edit data.
	 */
	public editUser(user: UserEdit): Observable<void> {
		return this.httpClient.patch<unknown>(this.apiUrls.users.editUser(user.id), this.userMapper.toEditUserDto(user)).pipe(
			this.appErrorMapper.catchHttpErrorToAppError(),
			map(() => undefined),
		);
	}

	/**
	 * Get analytics.
	 * @param filterParams Filter params.
	 */
	public getAnalytics(filterParams: UserAnalysisFilterParams): Observable<UserAnalytic> {
		const filterParamsDto = this.userAnalysisFilterParamsMapper.toDto(filterParams);
		const params = composeHttpParams(filterParamsDto);

		return this.httpClient.get<unknown>(this.apiUrls.users.analytics, { params })
			.pipe(
				map(response => userAnalyticDto.parse(response)),
				map(dto => this.userAnalyticMapper.fromDto(dto)),
			);
	}

	/**
	 * Activate user.
	 * @param userId User ID.
	 */
	public activateUser(userId: User['id']): Observable<void> {
		return this.httpClient.post<unknown>(this.apiUrls.users.activateUser(userId), {}).pipe(
			this.appErrorMapper.catchNonFieldErrorToAppError(),
			map(() => undefined),
		);
	}

	/**
	 * Deactivate user.
	 * @param userId User ID.
	 */
	public deactivateUser(userId: User['id']): Observable<void> {
		return this.httpClient.post<unknown>(this.apiUrls.users.deactivateUser(userId), {}).pipe(
			this.appErrorMapper.catchNonFieldErrorToAppError(),
			map(() => undefined),
		);
	}

}
