
import { Injectable } from '@angular/core';

import { DOLLAR_IN_CENTS } from '../constants/common';

import { Mapper } from './mappers';

/** Cents currency mapper. */
@Injectable({ providedIn: 'root' })
export class CentsCurrencyMapper implements Mapper<number, number> {

	/** @inheritdoc */
	public fromDto(data: number): number {
		return data / DOLLAR_IN_CENTS;
	}

	/** @inheritdoc */
	public toDto(data: number): number {
		return data * DOLLAR_IN_CENTS;
	}
}
