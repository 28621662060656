import { z } from 'zod';
import { Injectable } from '@angular/core';

import { PaginationDto } from '../dtos/pagination.dto';
import { Pagination } from '../models/pagination';

import { MapperFromDto } from './mappers';

type MapperFunction<TDto, TDomain> = (dto: TDto) => TDomain;

/** Pagination mapper. */
@Injectable({ providedIn: 'root' })
export class PaginationMapper {
	/**
	 * Map pagination from dto.
	 * @param page Dto page.
	 * @param mapper Mapper for the items.
	 */
	public fromDto<TDto extends z.ZodTypeAny, TDomain>(
		page: PaginationDto<TDto>,
		mapper:
		| MapperFromDto<Readonly<z.infer<TDto>>, TDomain>
		| MapperFunction<Readonly<z.infer<TDto>>, TDomain>,
	): Pagination<TDomain> {
		return new Pagination({
			items: page.results.map(typeof mapper === 'function' ? mapper : mapper.fromDto.bind(mapper)),
			totalCount: page.count,
			hasNext: !!page.next,
			hasPrev: !!page.previous,
		});
	}
}
