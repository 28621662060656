import { Injectable } from '@angular/core';

import { PerformanceDto } from '../dtos/performance.dto';
import { Performance } from '../models/performance';
import { reverseRecord } from '../utils/reverse-record';

import { Mapper } from './mappers';

const TO_PERFORMANCE_DTO: Readonly<Record<Performance, PerformanceDto>> = {
	average: 'average',
	overachiever: 'overachiever',
	underachiever: 'underachiever',
};

const FROM_PERFORMANCE_DTO_DTO = reverseRecord(TO_PERFORMANCE_DTO);

/** Performance mapper. */
@Injectable({ providedIn: 'root' })
export class PerformanceMapper implements Mapper<PerformanceDto, Performance> {

	/** @inheritdoc */
	public fromDto(data: PerformanceDto): Performance {
		return FROM_PERFORMANCE_DTO_DTO[data];
	}

	/** @inheritdoc */
	public toDto(data: Performance): PerformanceDto {
		return TO_PERFORMANCE_DTO[data];
	}
}
