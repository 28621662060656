import { inject, Injectable } from '@angular/core';

import { UserFilterParamsDto } from '../dtos/user-filter-params.dto';
import { UserSortFieldDto } from '../dtos/user.dto';
import { UserSortField } from '../models/user';
import { UsersFilterParams } from '../models/users-filter-params';

import { BaseFilterParamsMapper } from './base-filter-params.mapper';
import { ClassificationValueMapper } from './classification-value.mapper';
import { DateMapper } from './date.mapper';
import { MapperToDto } from './mappers';
import { SortMapper } from './sort.mapper';
import { UserRoleMapper } from './user-role.mapper';

const USER_SORT_FIELD_MAP: Readonly<Record<UserSortField, UserSortFieldDto>> = {
	firstName: 'first_name',
	lastName: 'last_name',
};

/** User filter params mapper. */
@Injectable({
	providedIn: 'root',
})
export class UserFilterParamsMapper
implements MapperToDto<UserFilterParamsDto, UsersFilterParams> {

	private readonly baseFilterParamsMapper = inject(BaseFilterParamsMapper);

	private readonly userRoleMapper = inject(UserRoleMapper);

	private readonly dateMapper = inject(DateMapper);

	private readonly sortMapper = inject(SortMapper);

	private readonly classificationValueMapper = inject(ClassificationValueMapper);

	/** @inheritdoc */
	public toDto(data: UsersFilterParams): UserFilterParamsDto {
		return {
			...this.baseFilterParamsMapper.mapCombinedOptionsToDto(data),
			job_id: data.jobId?.toString() ?? undefined,
			full_name: data.fullName ?? undefined,
			date_after: data.startDate ? this.dateMapper.toDto(data.startDate) : undefined,
			date_before: data.endDate ? this.dateMapper.toDto(data.endDate) : undefined,
			role__in: data.roles ? data.roles.map(role => this.userRoleMapper.toDto(role)).join(',') : undefined,
			ordering: data.sortOptions.field && data.sortOptions.direction ?
				this.sortMapper.toDto(data.sortOptions, USER_SORT_FIELD_MAP) :
				undefined,
			job_number: data.jobNumber ?? undefined,
			performance: data.performance ?? undefined,
			classification: data.classification ? this.classificationValueMapper.toDto(data.classification) : undefined,
			is_deactivated: data.isDeactivated ?? undefined,
		};
	}
}
