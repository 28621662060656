import { Injectable, inject } from '@angular/core';

import { UserRoleDto } from '../dtos/user-role.dto';
import { UserCreationDto, UserDto, UserEditDto } from '../dtos/user.dto';
import { ValidationErrorDto } from '../dtos/validation-error.dto';
import { EntityValidationErrors } from '../models/app-error';
import { ClassificationValue } from '../models/classification';
import { User, UserCreation, UserEdit } from '../models/user';

import { ClassificationValueMapper } from './classification-value.mapper';
import { DateMapper } from './date.mapper';
import { MapperFromDto, ValidationErrorMapper } from './mappers';
import { UserRoleMapper } from './user-role.mapper';

import { CentsCurrencyMapper } from './cents-currency.mapper';
import { extractErrorMessageByField } from './extract-error-message';

/**
 * Map classification to role.
 * @param value Classification value.
 */
function mapClassificationToRoleDto(value: ClassificationValue): UserRoleDto {
	switch (value) {
		case ClassificationValue.Admin:
			return UserRoleDto.Admin;
		case ClassificationValue.ProjectManager:
			return UserRoleDto.ProjectManager;
		case ClassificationValue.Foreman:
			return UserRoleDto.Foreman;
		default:
			return UserRoleDto.Crewman;
	}
}

/** User mapper. */
@Injectable({
	providedIn: 'root',
})
export class UserMapper implements MapperFromDto<UserDto, User>, ValidationErrorMapper<UserCreationDto, UserCreation> {
	private readonly userRoleMapper = inject(UserRoleMapper);

	private readonly classificationValueMapper = inject(ClassificationValueMapper);

	private readonly dateMapper = inject(DateMapper);

	private readonly centsCurrencyMapper = inject(CentsCurrencyMapper);

	/** @inheritdoc */
	public fromDto(dto: UserDto): User {
		return {
			id: dto.id,
			email: dto.email,
			firstName: dto.first_name,
			lastName: dto.last_name,
			classification: this.classificationValueMapper.fromDto(dto.classification),
			role: this.userRoleMapper.fromDto(dto.role),
			rate: dto.hourly_rate ? this.centsCurrencyMapper.fromDto(dto.hourly_rate) : null,
			hiredDate: dto.start_date ? this.dateMapper.fromDto(dto.start_date) : null,
			isDeactivated: dto.is_deactivated,
		};
	}

	/**
	 * Map user creation data to dto.
	 * @param data User creation data.
	 */
	public toCreationUserDto(data: UserCreation): UserCreationDto {
		return {
			email: data.email,
			password: data.password ?? undefined,
			first_name: data.firstName,
			last_name: data.lastName,
			hourly_rate: data.rate ? this.centsCurrencyMapper.toDto(data.rate) : null,
			role: mapClassificationToRoleDto(data.classification),
			classification: this.classificationValueMapper.toDto(data.classification),
			start_date: data.hiredDate ? this.dateMapper.toDto(data.hiredDate) : null,
		};
	}

	/**
	 * Map user edit data to dto.
	 * @param data User edit data.
	 */
	public toEditUserDto(data: UserEdit): UserEditDto {
		return {
			email: data.email,
			password: data.password ?? undefined,
			first_name: data.firstName,
			last_name: data.lastName,
			hourly_rate: data.rate ? this.centsCurrencyMapper.toDto(data.rate) : null,
			role: mapClassificationToRoleDto(data.classification),
			classification: this.classificationValueMapper.toDto(data.classification),
			start_date: data.hiredDate ? this.dateMapper.toDto(data.hiredDate) : null,
		};
	}

	/** @inheritdoc */
	public validationErrorFromDto(
		errorDto: ValidationErrorDto<UserCreationDto>,
	): EntityValidationErrors<UserCreation> {
		return {
			email: extractErrorMessageByField(errorDto, 'email'),
		};
	}

}
