import { Injectable } from '@angular/core';

import { reverseRecord } from '../utils/reverse-record';

import { ClassificationValueDto } from '../dtos/classification.dto';

import { ClassificationValue } from '../models/classification';

import { Mapper } from './mappers';

const CLASSIFICATION_VALUE_FROM_DTO_MAP: Readonly<Record<ClassificationValueDto, ClassificationValue>> = {
	[ClassificationValueDto.Admin]: ClassificationValue.Admin,
	[ClassificationValueDto.ProjectManager]: ClassificationValue.ProjectManager,
	[ClassificationValueDto.Foreman]: ClassificationValue.Foreman,
	[ClassificationValueDto.Master]: ClassificationValue.Master,
	[ClassificationValueDto.Apprentice]: ClassificationValue.Apprentice,
	[ClassificationValueDto.Journeyman]: ClassificationValue.Journeyman,
	[ClassificationValueDto.Other]: ClassificationValue.Other,
};

const CLASSIFICATION_VALUE_TO_DTO_MAP = reverseRecord(CLASSIFICATION_VALUE_FROM_DTO_MAP);

/** Classification value mapper. */
@Injectable({ providedIn: 'root' })
export class ClassificationValueMapper implements Mapper<ClassificationValueDto, ClassificationValue> {
	/** @inheritdoc */
	public fromDto(dto: ClassificationValueDto): ClassificationValue {
		return CLASSIFICATION_VALUE_FROM_DTO_MAP[dto];
	}

	/** @inheritdoc */
	public toDto(model: ClassificationValue): ClassificationValueDto {
		return CLASSIFICATION_VALUE_TO_DTO_MAP[model];
	}
}
