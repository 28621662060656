import { z } from 'zod';

import { performancesDto } from './performance.dto';
import { UserRoleDto } from './user-role.dto';
import { ClassificationValueDto } from './classification.dto';

/** User DTO schema. */
export const userDtoSchema = z.object({
	id: z.number(),
	email: z.string().nullable(),
	first_name: z.string(),
	last_name: z.string(),
	role: z.nativeEnum(UserRoleDto),
	classification: z.nativeEnum(ClassificationValueDto),
	hourly_rate: z.number().nullable(),
	start_date: z.string().nullable(),
	is_deactivated: z.boolean(),
});

/** User extended DTO schema. */
export const userExtendedDtoSchema = userDtoSchema.extend({
	production: z.string().nullable(),
	performance: z.enum(performancesDto).nullable(),
	job_number: z.string().nullable(),
	hour: z.string().nullable(),
	job_start_date: z.string().nullable(),
});

/** User DTO. */
export type UserDto = Readonly<z.infer<typeof userDtoSchema>>;

/** User extended DTO. */
export type UserExtendedDto = Readonly<z.infer<typeof userExtendedDtoSchema>>;

/** User creation dto. */
export type UserCreationDto = Pick<UserExtendedDto,
'email'
| 'first_name'
| 'last_name'
| 'start_date'
| 'hourly_rate'
| 'classification'
| 'role'> & {

	/** Password. */
	readonly password: string | undefined;
};

/** User creation dto. */
export type UserEditDto = UserCreationDto;

/** User available sort fields dto. */
export const userSortFieldsDto = ['first_name', 'last_name'] as const satisfies (keyof UserDto)[];

/** User sort field dto. */
export type UserSortFieldDto = typeof userSortFieldsDto[number];
