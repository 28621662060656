import { DateTime } from 'luxon';

import { DEFAULT_DATE_FORMAT } from '../constants/date-format';

export namespace DateUtils {

	/**
	 * Whether date is valid or not.
	 * @param date Date.
	 */
	export function isValidDate(date: string): boolean {
		const parsedDate = DateTime.fromFormat(date, DEFAULT_DATE_FORMAT);
		return parsedDate.isValid;
	}

	/**
	 * Whether date is valid or not.
	 * @param date Date.
	 * @param format Date format.
	 */
	export function isValidDateFromString(date: string, format = DEFAULT_DATE_FORMAT): boolean {
		const parsedDate = DateTime.fromFormat(date, format);
		return parsedDate.isValid;
	}

	/**
	 * Get date from query param.
	 * @param date Date to get.
	 */
	export function getDateFromQueryParams(date: string | null): Date {
		if (date == null || !DateUtils.isValidDateFromString(date)) {
			return getToday();
		}
		return DateTime.fromFormat(date, DEFAULT_DATE_FORMAT).toJSDate();
	}

	/** Get today. */
	export function getToday(): Date {
		return DateTime.fromJSDate(new Date()).toJSDate();
	}

	/** Get yesterday. */
	export function getYesterday(): Date {
		return DateTime.fromJSDate(new Date()).minus({ days: 1 })
			.toJSDate();
	}
}
