import { Injectable, inject } from '@angular/core';

import { UserExtendedDto } from '../dtos/user.dto';
import { UserExtended } from '../models/user';

import { DateMapper } from './date.mapper';
import { MapperFromDto } from './mappers';
import { PerformanceMapper } from './performance.mapper';
import { UserMapper } from './user.mapper';

/** User extended mapper. */
@Injectable({
	providedIn: 'root',
})
export class UserExtendedMapper implements MapperFromDto<UserExtendedDto, UserExtended> {
	private readonly userMapper = inject(UserMapper);

	private readonly dateMapper = inject(DateMapper);

	private readonly performanceMapper = inject(PerformanceMapper);

	/** @inheritdoc */
	public fromDto(dto: UserExtendedDto): UserExtended {
		return {
			...this.userMapper.fromDto(dto),
			production: Number(dto.production),
			performance: dto.performance ? this.performanceMapper.fromDto(dto.performance) : null,
			hour: Number(dto.hour),
			jobNumber: dto.job_number,
			jobStartDate: dto.job_start_date ? this.dateMapper.fromDto(dto.job_start_date) : null,
		};
	}
}
