import { z } from 'zod';

/** User analytic schema. */
export const userAnalyticDto = z.object({
	user_count: z.number(),
	absent_count: z.number(),
	avg_labor_rate: z.string(),
	over_production_count: z.number(),
	under_production_count: z.number(),
});

/** User analytic. */
export type UserAnalyticDto = Readonly<z.infer<typeof userAnalyticDto>>;
