import { Injectable } from '@angular/core';

import { UserRoleDto } from '../dtos/user-role.dto';
import { UserRole } from '../models/user-role';
import { reverseRecord } from '../utils/reverse-record';

import { Mapper } from './mappers';

const TO_USER_ROLE_DTO: Readonly<Record<UserRole, UserRoleDto>> = {
	[UserRole.SuperAdmin]: UserRoleDto.SuperAdmin,
	[UserRole.Admin]: UserRoleDto.Admin,
	[UserRole.Foreman]: UserRoleDto.Foreman,
	[UserRole.ProjectManager]: UserRoleDto.ProjectManager,
	[UserRole.Crewman]: UserRoleDto.Crewman,
};

const FROM_USER_ROLE_DTO = reverseRecord(TO_USER_ROLE_DTO);

/** User role mapper. */
@Injectable({ providedIn: 'root' })
export class UserRoleMapper implements Mapper<UserRoleDto, UserRole> {

	/** @inheritdoc */
	public fromDto(data: UserRoleDto): UserRole {
		return FROM_USER_ROLE_DTO[data];
	}

	/** @inheritdoc */
	public toDto(data: UserRole): UserRoleDto {
		return TO_USER_ROLE_DTO[data];
	}
}
