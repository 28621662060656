import { z } from 'zod';

import { UserRole } from './user-role';

/** Classification value dto. */
export enum ClassificationValue {
	Admin = 'admin',
	ProjectManager = 'projectManager',
	Foreman = 'foreman',
	Master = 'master',
	Journeyman = 'journeyman',
	Apprentice = 'apprentice',
	Other = 'other',
}

/** Classifications. */
export const classificationSchema = z.object({
	/** Value. */
	value: z.nativeEnum(ClassificationValue),

	/** Label. */
	label: z.string(),
});

/** Classification. */
export type Classification = Readonly<z.infer<typeof classificationSchema>>;

export namespace Classification {

	const MAP_TO_READABLE: Record<ClassificationValue, string> = {
		[ClassificationValue.Admin]: 'Admin',
		[ClassificationValue.ProjectManager]: 'Project Manager',
		[ClassificationValue.Foreman]: 'Foreman',
		[ClassificationValue.Master]: 'Master',
		[ClassificationValue.Journeyman]: 'Journeyman',
		[ClassificationValue.Apprentice]: 'Apprentice',
		[ClassificationValue.Other]: 'Other',
	};

	/**
	 * Map classification to role.
	 * @param value Classification value.
	 */
	export function mapClassificationToRole(value: Classification['value']): UserRole {
		switch (value) {
			case ClassificationValue.Admin:
				return UserRole.Admin;
			case ClassificationValue.ProjectManager:
				return UserRole.ProjectManager;
			case ClassificationValue.Foreman:
				return UserRole.Foreman;
			default:
				return UserRole.Crewman;
		}
	}

	/**
	 * To human-readable value.
	 * @param value Value.
	 */
	export function toReadable(value: Classification['value']): string {
		return MAP_TO_READABLE[value];
	}
}
