import { Injectable, inject } from '@angular/core';

import { UserAnalyticDto } from '../dtos/user-analytic.dto';
import { UserAnalytic } from '../models/user-analytic';

import { MapperFromDto } from './mappers';
import { CentsCurrencyMapper } from './cents-currency.mapper';

/** User analytic mapper. */
@Injectable({
	providedIn: 'root',
})
export class UserAnalyticMapper implements MapperFromDto<UserAnalyticDto, UserAnalytic> {

	private readonly centsCurrencyMapper = inject(CentsCurrencyMapper);

	/** @inheritdoc */
	public fromDto(dto: UserAnalyticDto): UserAnalytic {
		return {
			userCount: dto.user_count,
			absentCount: dto.absent_count,
			avgLaborRate: this.centsCurrencyMapper.fromDto(Number(dto.avg_labor_rate)),
			overProductionCount: dto.over_production_count,
			underProductionCount: dto.under_production_count,
		};
	}
}
