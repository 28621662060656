import { Injectable, inject } from '@angular/core';

import { UserAnalysisFilterParamsDto } from '../dtos/user-analysis-filter-params.dto';
import { UserAnalysisFilterParams } from '../models/user-analysis-filter-params';

import { DateMapper } from './date.mapper';
import { MapperToDto } from './mappers';

/** User analysis filter params mapper. */
@Injectable({
	providedIn: 'root',
})
export class UserAnalysisFilterParamsMapper
implements MapperToDto<UserAnalysisFilterParamsDto, UserAnalysisFilterParams> {

	private readonly dateMapper = inject(DateMapper);

	/** @inheritdoc */
	public toDto(data: UserAnalysisFilterParams): UserAnalysisFilterParamsDto {
		return {
			date_after: data.startDate ? this.dateMapper.toDto(data.startDate) : undefined,
			date_before: data.endDate ? this.dateMapper.toDto(data.endDate) : undefined,
		};
	}
}
