import { Injectable, inject } from '@angular/core';

import { UserDetailFilterParamsDto } from '../dtos/user-detail-filter-params.dto';

import { UserDetailFilterParams } from '../models/user-detail-filter-params';

import { DateMapper } from './date.mapper';
import { MapperToDto } from './mappers';

/** User detail filter params mapper. */
@Injectable({
	providedIn: 'root',
})
export class UserDetailFilterParamsMapper implements MapperToDto<UserDetailFilterParamsDto, UserDetailFilterParams> {
	private readonly dateMapper = inject(DateMapper);

	/** @inheritdoc */
	public toDto(data: UserDetailFilterParams): UserDetailFilterParamsDto {
		return {
			date_after: data.startDate ? this.dateMapper.toDto(data.startDate) : undefined,
			date_before: data.endDate ? this.dateMapper.toDto(data.endDate) : undefined,
		};
	}
}
