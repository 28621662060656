import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';

import { DateUtils } from '../utils/date-utils';
import { DEFAULT_DATE_FORMAT } from '../constants/date-format';

import { Mapper } from './mappers';

/** Date mapper. */
@Injectable({
	providedIn: 'root',
})
export class DateMapper implements Mapper<string, Date> {

	/** @inheritdoc */
	public fromDto(data: string): Date {
		if (DateUtils.isValidDate(data)) {
			return DateTime.fromFormat(data, DEFAULT_DATE_FORMAT).toJSDate();
		}
		return DateUtils.getToday();
	}

	/** @inheritdoc */
	public toDto(data: Date): string {
		return DateTime.fromJSDate(data).toFormat(DEFAULT_DATE_FORMAT);
	}
}
