import { Injectable, inject } from '@angular/core';

import { User } from '../models/user';

import { Job } from '../models/job';

import { AppConfig } from './app.config';

/**
 * Urls used within the application.
 * Stringified for convenience, since most of the Angular's HTTP tools work with strings.
 */
@Injectable({ providedIn: 'root' })
export class AppUrlsConfig {
	private readonly appConfigService = inject(AppConfig);

	/** Auth-related routes. */
	public readonly auth = {
		login: this.toApi('auth/login/'),
		logout: this.toApi('auth/logout/'),
		refreshSecret: this.toApi('auth/password-reset/'),
		resetPassword: this.toApi('auth/token/refresh/'),
		confirmPasswordReset: this.toApi('auth/password-reset-confirm/'),
	} as const;

	/** Routes for getting/editing current user's info. */
	public readonly user = {
		currentProfile: this.toApi('users/profile/'),
		changePassword: this.toApi('users/change_password/'),
	} as const;

	/** Routes for users' info. */
	public readonly users = {
		classifications: this.toApi('users/classifications/'),
		users: this.toApi('users/'),
		createUser: this.toApi('users/'),
		editUser: (id: User['id']) => this.toApi('users', `${id}/`),
		details: (id: User['id']) => this.toApi('users', `${id}/`),
		list: this.toApi('users/'),
		analytics: this.toApi('users/analytics/'),
		activateUser: (id: User['id']) => this.toApi('users', `${id}`, 'activate/'),
		deactivateUser: (id: User['id']) => this.toApi('users', `${id}`, 'deactivate/'),
		userMembers: this.toApi('users/user-detail/member/'),
		userMaterials: this.toApi('users/user-detail/material/'),
		userPhases: this.toApi('users/user-detail/phase/'),
		userMembersDetail: this.toApi('users/user-detail/'),
	} as const;

	/** Routes for jobs. */
	public readonly jobs = {
		reports: this.toApi('jobs/reports/'),
		updateReport: (id: number) => this.toApi(`jobs/reports/${id}/`),
		createJob: this.toApi('jobs/'),
		createNonWorkingDay: this.toApi('jobs/non-working-days/'),
		getNonWorkingDays: this.toApi('jobs/non-working-days/'),
		materials: this.toApi('jobs/materials/'),
		phases: this.toApi('jobs/phases/'),
		jobsAnalytics: this.toApi('jobs/analytics/'),
		jobs: this.toApi('jobs/'),
		jobMembers: this.toApi('jobs/job-detail/member/'),
		jobMaterials: this.toApi('jobs/job-detail/material/'),
		jobPhases: this.toApi('jobs/job-detail/phase/'),
		jobMembersDetail: this.toApi('jobs/job-detail/'),
		jobLogs: this.toApi('jobs/job-logs/'),
		importJobsPhase: (id: Job['id']) => this.toApi(`jobs/${id}/job-phases/`),
		details: (id: Job['id']) => this.toApi(`jobs/${id}/`),
		editJob: (id: Job['id']) => this.toApi(`jobs/${id}/`),
		detailReport: (id: Job['id']) => this.toApi(`jobs/reports/${id}/`),
		latestReportDate: (id: Job['id']) => this.toApi(`jobs/${id}/latest_report_date/`),
		exportJobLogs: this.toApi('jobs/job-detail/export/'),
		exportJobMembers: this.toApi('jobs/job-detail/member/export/'),
		exportJobPhases: this.toApi('jobs/job-detail/phase/export/'),
		exportJobMaterials: this.toApi('jobs/job-detail/material/export/'),
	} as const;

	/** Routes for material. */
	public readonly materials = {
		materials: this.toApi('jobs/materials/'),
	} as const;

	/** Routes for phases. */
	public readonly phases = {
		phases: this.toApi('jobs/phases/'),
	} as const;

	/**
	 * Checks whether the url is application-scoped.
	 * @param url Url to check.
	 */
	public isApplicationUrl(url: string): boolean {
		return url.startsWith(this.appConfigService.apiUrl);
	}

	/**
	 * Checks whether the specified url is calling an auth-related endpoint.
	 * @param url Url to check.
	 */
	public isAuthUrl(url: string): boolean {
		return Object.values(this.auth).find(authUrl => authUrl.includes(url)) != null;
	}

	private toApi(...args: readonly string[]): string {
		const path = args.join('/');
		return new URL(path, this.appConfigService.apiUrl).toString();
	}
}
