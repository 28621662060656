import { TypeOf, z } from 'zod';

/**
 * Creates pagination DTO schema.
 * @param itemSchema Some item schema.
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function createPaginationDtoSchemaParser<T extends z.ZodTypeAny>(
	itemSchema: T,
) {
	return z
		.object({
			count: z.number(),
			next: z.string().nullable(),
			previous: z.string().nullable(),
			results: z.array(itemSchema),
		})
		.strict();
}

/** Pagination DTO. */
export type PaginationDto<T extends z.ZodTypeAny> =
  TypeOf<ReturnType<typeof createPaginationDtoSchemaParser<T>>>;
