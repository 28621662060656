import { z } from 'zod';

/** Classification value dto. */
export enum ClassificationValueDto {
	Admin = 'admin',
	ProjectManager = 'project_manager',
	Foreman = 'foreman',
	Master = 'master',
	Journeyman = 'journeyman',
	Apprentice = 'apprentice',
	Other = 'other',
}

/** Classification dto. */
export const classificationDtoSchema = z.object({
	/** Value. */
	value: z.nativeEnum(ClassificationValueDto),

	/** Label. */
	label: z.string(),
});

/** Classification dto. */
export type ClassificationDto = Readonly<z.infer<typeof classificationDtoSchema>>;
